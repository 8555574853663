import React from 'react'
import Navbar from "../../Components/Navbar/Navbar"
import Updateusercomp from '../../Components/Users/Edituser/Updateusercomp'

function Updateuser() {
  return (
    <div>
      <Navbar/>
      <Updateusercomp/>
    </div>
  )
}

export default Updateuser