import React from 'react'
import Navbar from "../../Components/Navbar/Navbar"
import Addticketcomp from "../../Components/Tickets/Addticket/Addticketcomp"

function Addticket() {
  return (
    <div>
      <Navbar/>
      <Addticketcomp/>
    </div>
  )
}

export default Addticket