import React from 'react'
import Navbar from "../../Components/Navbar/Navbar"
import Ticketscomp from "../../Components/Tickets/Tickets/Ticketscomp"

const Tickets = () => {
  return (
    <div>
      <Navbar/>
      <Ticketscomp/>

    </div>
  )
}

export default Tickets