import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import AddProjectcomp from "../../Components/AddProject/AddProjectcomp"

const AddProject = () => {
  return (
    <div>
      <Navbar/>
      <AddProjectcomp/>



    </div>
  )
}

export default AddProject