import React from 'react'
import EditProject from '../../Components/EditProject/EditProject'
import Navbar from '../../Components/Navbar/Navbar'

const Updateproject = () => {
  return (
    <div>
        <Navbar/>
        <EditProject/>
    </div>
  )
}

export default Updateproject