import React from 'react'
import Navbar from "../../Components/Navbar/Navbar"
import Updateticketcomp from '../../Components/Tickets/Editticket/Updateticketcomp'

function Updateticket() {
  return (
    <div>
      <Navbar/>
      <Updateticketcomp/>
    </div>
  )
}

export default Updateticket