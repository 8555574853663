import React from 'react'
import Navbar from "../../Components/Navbar/Navbar"
import Userscomp from '../../Components/Users/Users/Userscomp.jsx'

const Users = () => {
  return (
    <div>
      <Navbar/>
      <Userscomp/>

    </div>
  )
}

export default Users