import React from 'react'
import Navbar from "../../Components/Navbar/Navbar"
import Addusercomp from "../../Components/Users/Adduser/Addusercomp"

function Adduser() {
  return (
    <div>
      <Navbar/>
      <Addusercomp/>
    </div>
  )
}

export default Adduser